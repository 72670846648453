.ant-table,
.ant-table-thead > tr > th {
  color: #ffffff;
  background-color: var(--page-background-color);
}

.ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #252525;
}

.ant-table-thead {
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
}

.ant-table-thead > tr > th {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}

td.ant-table-column-sort {
  background-color: var(--page-background-color);
}

.ant-table-thead th.ant-table-column-sort {
  background: #303030;
}

.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #ffe406;
}

.ant-pagination-item:hover {
  transition: all 0.1s;
  transform: scale(1.2);
}

.ant-pagination-item:not(.ant-pagination-item-active) a {
  color: #ffffff;
  font-size: 16px;
}

.ant-pagination-prev button,
.ant-pagination-next button {
  color: #ffffff;
}

.ant-pagination-prev button:hover,
.ant-pagination-next button:hover {
  color: #ffe406 !important;
}

.ant-pagination-prev button:hover span,
.ant-pagination-next button:hover span {
  color: #ffe406;
}

.ant-pagination-item-active {
  background: #303030;
  border: 1px solid #303030 !important;
  border-radius: 6px;
  color: #ffe406;
}

.ant-pagination-item-active a:hover {
  color: #ffe406;
  background: #303030;
  border: 1px solid #303030 !important;
  border-radius: 6px;
}

.ant-pagination-item-active a {
  color: #ffe406;
  background: #303030;
  border-radius: 6px;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #ffffff;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #ffffff;
}

.ant-spin-dot-item {
  background-color: #ffe406;
}

.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #303030;
}

.ant-empty-description {
  color: #ffffff;
}
