@import '~antd/dist/antd.css';
@import 'shared/webgl/scenes/eighth-wall-scene/HTMLElements/styles/loader-8w.css';
@import 'shared/webgl/scenes/eighth-wall-scene/HTMLElements/styles/tap-to-place-8w.css';
@import 'shared/webgl/scenes/eighth-wall-scene/HTMLElements/styles/black-background-before-eighth-wall.css';
@import 'antd-table-normalize.css';
@import 'antd-notification-normalize.css';

@font-face {
  font-family: 'Inter Regular';
  src: url('assets/fonts/Inter/Inter-Regular.ttf');
  src: url('assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Regular';
  src: url('assets/fonts/Inter/Inter-Bold.ttf');
  src: url('assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Regular';
  src: url('assets/fonts/Inter/Inter-Medium.ttf');
  src: url('assets/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter Regular';
  src: url('assets/fonts/Inter/Inter-Light.ttf');
  src: url('assets/fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'FiraCode Regular';
  src: url('assets/fonts/FiraCode/FiraCode-Regular.ttf');
}

* {
  box-sizing: border-box !important;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
  height: initial !important;
  overflow-y: initial !important;
  overflow-x: clip;
  background-color: var(--page-background-color);
  font-family: 'Inter Regular', sans-serif;
}

body.with-transparent-background {
  background: transparent;
}

body.no-scroll {
  overflow: hidden !important;
}

a {
  text-decoration: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

select {
  font-family: inherit;
}

input,
textarea {
  outline: none;
  font-family: 'Inter Regular', sans-serif;
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  outline: none;
  font-family: 'Inter Regular', sans-serif;
}

button:disabled {
  pointer-events: none;
  background-color: #ccc;
  opacity: 0.5;
}

input:disabled {
  pointer-events: none;
  opacity: 0.5;
}

img {
  max-width: 100%;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  border: none;
}

::-webkit-scrollbar-thumb {
  background: #6E6E6E;
  border-radius: 2px;
  height: 30px;
}

#ARSceneCanvas {
  position: fixed;
  top: 0;
  z-index: 10;
  height: 0;
  width: 0;
}

#loadingContainer {
  z-index: 2000;
}

#almostthereContainer {
  z-index: 2001;
}

.grecaptcha-badge {
  visibility: hidden;
}

#userback_button_container {
  display: none;
}

#userback_button_container .userback-button-e {
  top: initial !important;
  bottom: 20% !important;
  transition: all 0s !important;
}

@media (max-width: 768px) {
  .userback-button {
    z-index: 10 !important;
  }
}

.userback-button-e.left-side {
  left: 0 !important;
  right: initial !important;
  transform: rotate(90deg) translate(0%, 100%) !important;
  transform-origin: 50% 50% !important;
}

@media (max-width: 768px) {
  .userback-button-e, .userback-button-e.left-side {
    right: 0 !important;
    left: initial !important;
    transform: rotate(-90deg) translate(0%, 100%) !important;
    transform-origin: 50% 50% !important;
  }
}

.ant-message {
  top: 210px;
  transform: translateY(-50%);
  z-index: 1070;
}

@media (max-width: 768px) {
  .ant-message {
    top: 165px;
  }
}

.ant-message-notice-content {
  padding: 0;
  background: rgba(0, 0, 0, 80%);
  border-radius: 4px;
  backdrop-filter: blur(4px);
}
