@font-face {
  font-family: 'Inter';
  src: url("../../../../../../assets/fonts/Inter/Inter-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url("../../../../../../assets/fonts/Inter/Inter-Bold.ttf");
  font-weight: 700;
}

#loadingContainer #loadBackground {
  background-color: #101010;
  overflow: hidden;
  z-index: 1000000;
}

#loadingContainer #requestingCameraPermissions {
  display: none;
}

#loadingContainer #loadImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#loadingContainer #loadImageContainer .load-background-main-block {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

#loadingContainer #loadImage {
  position: initial;
  margin: 0;
  top: 194px;
  width: 253px;
  height: 173px;
  object-fit: contain;
  transform: initial;
  animation: none;
}

#loadingContainer #loadImageContainer .load-text-8w {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 125%;
  color: #ffffff;
}

#loadingContainer .poweredby-img {
  z-index: 1000000;
}

.prompt-box-8w {
  padding: 26px 35px !important;
  font-family: 'Inter', serif !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 22px !important;
  color: #ffffff !important;
  background-color: #0A0A0A !important;
  border-radius: 8px !important;
}

.prompt-button-8w {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 14px !important;
  padding: 14px !important;
  font-family: inherit !important;
  font-weight: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  color: #ffffff !important;
  background-color: #0A0A0A !important;
  border-radius: 3px !important;
}

.button-primary-8w {
  margin-top: 14px !important;
  background-color: #FFE406 !important;
  border-radius: 3px !important;
  color: #030303 !important;
}
