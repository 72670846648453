@font-face {
  font-family: 'Inter';
  src: url("../../../../../../assets/fonts/Inter/Inter-SemiBold.ttf");
  font-weight: 600;
}

.tap-to-place-8w {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 11;
  pointer-events: none;
}

.tap-to-place-8w .tap-to-place-8w__image {
  position: fixed;
  bottom: 24vh;
}

.tap-to-place-8w .tap-to-place-8w__text {
  position: fixed;
  bottom: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  max-width: 60%;
  height: 66px;
  color: #1A1704;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110.02%;
  text-align: center;
  background-color: #FFE406;
;
}
