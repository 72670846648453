.ant-notification {
  z-index: 1070;
  top: 68px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message,
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 50px;
}

.ant-notification .notification-message {
  text-align: left;
  font-size: 14px;
  line-height: 16px;
}

.ant-notification .notification-description {
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
}

.ant-notification .ant-notification-notice {
  padding: 10px 24px 10px 10px;
  width: 500px;
}

.ant-notification .ant-notification-notice-content {
  padding: 8px;
}

.ant-notification .success-message .ant-notification-notice-content {
  border-left: 2px solid #0CD085;
}

.ant-notification .error-message .ant-notification-notice-content {
  border-left: 2px solid #FF0202;
}

.ant-notification .warning-message .ant-notification-notice-content {
  border-left: 2px solid #ffe406;
}

.ant-notification .ant-notification-notice-icon {
  top: 50%;
  margin-left: 10px;
  transform: translateY(-50%);
  font-size: 20px;
}

@media (max-width: 768px) {
  .ant-notification {
    top: 70px !important;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: 14px;
  }

  .ant-notification-notice-with-icon .ant-notification-notice-description {
    font-size: 12px;
  }
}

.scene-notification {
  right: 200px !important;
}

.full-viewer-width {
  right: 0 !important;
}

.zen-mode {
  top: 35px !important;
}

.scene-notification .ant-notification-notice-with-icon .ant-notification-notice-message,
.scene-notification .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 40px;
}

.scene-notification .notification-message {
  text-align: left;
  font-size: 12px;
  line-height: 13px;
}

.scene-notification .notification-description {
  text-align: left;
  font-size: 11px;
  line-height: 12px;
  margin-top: 6px;
}

.scene-notification .ant-notification-notice {
  padding: 4px 8px 4px 4px;
  width: 320px;
  border: none !important;
}

.scene-notification .ant-notification-notice-content {
  border-left: 2px solid #ffe406;
  padding: 8px;
}

.scene-notification .ant-notification-notice-close {
  top: 4px;
  right: 6px;
}

.scene-notification .ant-notification-notice-icon {
  top: 50%;
  margin-left: 6px;
  transform: translateY(-50%);
  font-size: 18px;
}

@media (max-width: 992px) {
  .scene-notification {
    right: 0 !important;
  }
}
